import React from "react";

const Win98Theme = () => {
  return (
    <div style={{ margin: 0, padding: 0, width: '100vw', height: '100vh', overflow: 'hidden' }}>
        <iframe
            title="windows98-theme_html"
            sandbox="allow-scripts allow-same-origin"
            src="../win98-theme/Apple-Sammlung_retro.html"
            style={{ width: '100%', height: '100%', border: 'none' }}>
        </iframe>
    </div>
    );
};

export default Win98Theme;
