import React from "react";
import "../styles/Placeholder.css";

const Placeholder = () => {
  return (
    <div className="placeholder-container">
      <img
        src="/assets/images/Apple_Macintosh.png"
        alt="Bild von Apple-Sammlung"
        className="placeholder-image"
      />
      <h1 className="placeholder-title">Device Stories</h1>
      <p className="image-credit">
        Image by{" "}
        <a href="https://www.enter.ch" target="_blank" rel="noreferrer">
          Enter Technikwelt Solothurn. (2023, March 6). Bild von Apple-Sammlung
        </a>
      </p>
    </div>
  );
};

export default Placeholder;
