import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UICard from "./UICard";
import { UICardType } from "../types/UICardType";
import "../styles/UICards.css";

const UICards = () => {
  const containerRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const UICardsURL = "/exhibits/ui-cards";

  const cards: UICardType[] = useMemo(
    () => [
      {
        id: "lisa-os",
        os: "Lisa OS",
        type: "Apple",
        osImg: "Lisa-OS",
        release: "1983",
        hw: "Apple Lisa",
        description:
          "Lisa OS war das Betriebssystem des Apple Lisa, einer der ersten Personal Computer mit einer grafischen Benutzeroberfläche. Es war auf professionelle Geschäftsanwender ausgerichtet und bot Funktionen, welche die Produktivität steigern sollten.",
        innovations: [
          {
            title: "Grafische Benutzeroberfläche (GUI)",
            description:
              "Eines der ersten GUIs, die es Nutzern ermöglichte, mit Fenstern, Icons und Menüs zu arbeiten, was die Bedienung intuitiver machte.",
          },
          {
            title: "Mausunterstützung",
            description:
              "Ermöglichte die Interaktion mit dem System durch Zeigen und Klicken, für eine wesentlich einfachere Bedienung verglichen mit textbasierten Systemen",
          },
          {
            title: "Desktop-Metapher",
            description:
              "Nutzte die Desktop-Metapher, um Dateien und Anwendungen auf einer visuellen Oberfläche zu organisieren.",
          },
          {
            title: "Kooperatives Multitasking",
            description:
              "Ermöglichte das gleichzeitige Ausführen mehrerer Anwendungen.",
          },
        ],
        relevance:
          "Lisa OS war revolutionär für seine Zeit und legte den Grundstein für viele der grafischen Benutzeroberflächen, die später entwickelt wurden. Es setzte Massstäbe in Bezug auf Benutzerfreundlichkeit und intuitiver Bedienung, die in späteren Betriebssystemen wie macOS und Windows weiterentwickelt und verfeinert wurden.",
      },
      {
        id: "system-1",
        os: "System 1",
        type: "Apple",
        osImg: "System-1",
        release: "1984",
        hw: "Macintosh 128K",
        description:
          "System 1 war das erste Betriebssystem für den Apple Macintosh und brachte eine benutzerfreundliche grafische Benutzeroberfläche in den Mainstream. Es war darauf ausgelegt, den Zugang zu Computern für eine breitere Öffentlichkeit zu erleichtern.",
        innovations: [
          {
            title: "Grafische Benutzeroberfläche (GUI)",
            description:
              "Eine der ersten GUIs, die kommerziell erfolgreich waren.",
          },
          {
            title: "Mausunterstützung",
            description:
              "Ermöglichte präzise und intuitive Interaktionen durch Zeigen und Klicken.",
          },
          {
            title: "Desktop-Metapher",
            description:
              "Nutzte die Desktop-Metapher zur Organisation von Dateien und Anwendungen auf einer visuellen Oberfläche.",
          },
          {
            title: "Finder",
            description:
              "Ein integriertes Dateiverwaltungssystem, das Benutzern half, Dateien zu organisieren und zu finden.",
          },
          {
            title: "Integrierte Anwendungen",
            description:
              "Enthielt grundlegende Anwendungen wie MacWrite und MacPaint, die grundlegende Textverarbeitungs- und Grafikbearbeitungsfunktionen boten.",
          },
        ],
        relevance:
          "System 1 legte den Grundstein für die Entwicklung von modernen Betriebssystemen mit grafischen Benutzeroberflächen. Viele der von System 1 eingeführten Konzepte sind heute in Betriebssystemen wie macOS, Windows und verschiedenen Linux-Distributionen weiterhin präsent.",
      },
      {
        id: "nextstep",
        os: "NeXTSTEP",
        type: "NeXT",
        release: "1989",
        osImg: "NeXTSTEP",
        hw: "NeXT Computer (NeXTcube, NeXTstation)",
        description:
          "NeXTSTEP war ein fortschrittliches Betriebssystem, das auf NeXT-Computern lief. Es kombinierte eine leistungsstarke grafische Benutzeroberfläche mit einem Unix-basierten Kern und war besonders bei Entwicklern und im akademischen Bereich beliebt.",
        innovations: [
          {
            title: "Objektorientierte Programmierung (OOP)",
            description:
              "Unterstützte objektorientierte Programmierung mit Entwicklungswerkzeugen wie Interface Builder.",
          },
          {
            title: "Display PostScript",
            description:
              "Nutze Display PostScript für präzise und skalierbare Grafiken.",
          },
          {
            title: "Fortschrittliche GUI",
            description:
              "Ein GUI mit dreidimensionalen Fenstern und komplexen grafischen Elementen, die modern und ansprechend war.",
          },
          {
            title: "Innovative Entwicklungswerkzeuge",
            description:
              "Tools wie Interface Builder erleichterten die Entwicklung komplexer Anwendungen.",
          },
          {
            title: "Präemptives Multitasking",
            description:
              "Ermöglichte eine effizientere und stabilere Verwaltung mehrerer gleichzeitig laufender Anwendungen.",
          },
        ],
        relevance:
          "NeXTSTEP war wegweisend für die Entwicklung moderner Betriebssysteme. Viele seiner Technologien und Konzepte wurden in macOS übernommen und weiterentwickelt. Es legte den Grundstein für viele der fortschrittlichen Funktionen und Benutzeroberflächenelemente, die heute in modernen Betriebssystemen zu finden sind.",
      },
      {
        id: "os-x",
        os: "Mac OS X",
        type: "Apple",
        release: "2001",
        osImg: "MacOSX",
        hw: "Apple iMac, Power Mac G3, PowerBook G3",
        description:
          "Mac OS X war das erste moderne Betriebssystem von Apple, das auf einem Unix-basierten Kern aufbaute. Es bot eine stabile und sichere Plattform mit einer benutzerfreundlichen und visuell ansprechenden Oberfläche.",
        innovations: [
          {
            title: "Unix-basierter Kern",
            description:
              "Basiert auf einem stabilen und sicheren Unix-Kern, der von NeXTSTEP übernommen wurde.",
          },
          {
            title: "Aqua-Benutzeroberfläche",
            description:
              "Eine neue, visuell ansprechende Benutzeroberfläche mit halbtransparenten Elementen und Animationen.",
          },
          {
            title: "Dock",
            description:
              "Ein zentrales Element der Benutzeroberfläche, das schnellen Zugriff auf Anwendungen bietet.",
          },
          {
            title: "Quartz Grafiksystem",
            description:
              "Ein modernes Grafiksystem, das PostScript-ähnliche Rendering-Technologien nutzt und visuelle Effekte ermöglicht.",
          },
        ],
        relevance:
          "Mac OS X war ein Wendepunkt für Apple und legte den Grundstein für alle modernen Versionen von macOS. Es kombinierte Stabilität und Sicherheit mit einer benutzerfreundlichen und ansprechenden Oberfläche. Viele der in Mac OS X eingeführten Konzepte und Technologien sind weiterhin in aktuellen Versionen von macOS präsent und haben die Art und Weise, wie Benutzer mit Computern interagieren, nachhaltig beeinflusst.",
      },
    ],
    [],
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const cardId = location.pathname.split("/").pop() || "";
    if (cardId) {
      let index: number;
      let cardElement: HTMLElement | null = null;
      if (cardId === "start") {
        index = -1;
        cardElement = document.getElementById(`start-page`);
      } else if (cardId === "end") {
        index = cards.length;
        cardElement = document.getElementById(`end-page`);
      } else {
        index = cards.findIndex((card) => card.id === cardId);
        cardElement = document.getElementById(`${cardId}`);
      }
      setCurrentIndex(index);
      if (cardElement) {
        cardElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      //navigate(`${UICardsURL}/start`);
      setCurrentIndex(-1);
    }
  }, [location.pathname, cards, navigate, currentIndex]);

  const handlePrev = useCallback(
    (e: any) => {
      if (currentIndex !== -1) {
        const prevIndex = currentIndex - 1;
        if (prevIndex === -1) {
          navigate(`${UICardsURL}/start`);
        } else {
          navigate(`${UICardsURL}/${cards[prevIndex].id}`);
        }
        setCurrentIndex(prevIndex);
      }
    },
    [cards, currentIndex, navigate],
  );

  const handleNext = useCallback(
    (e: any) => {
      if (currentIndex !== cards.length) {
        const nextIndex = currentIndex + 1;
        if (nextIndex === cards.length) {
          navigate(`${UICardsURL}/end`);
        } else {
          navigate(`${UICardsURL}/${cards[nextIndex].id}`);
        }
        setCurrentIndex(nextIndex);
      }
    },
    [cards, currentIndex, navigate],
  );

  const handleKeyDown = useCallback(
    (e: any) => {
      if (!isMobile) {
        // Only enable arrow key navigation on desktop
        if (e.key === "ArrowLeft") {
          handlePrev(e);
        } else if (e.key === "ArrowRight") {
          handleNext(e);
        }
      }
    },
    [isMobile, handlePrev, handleNext],
  );

  const handleWheel = useCallback(
    (e: any) => {
      if (isMobile && containerRef.current) {
        e.preventDefault();
        if (e.deltaY > 0) {
          handleNext(e);
        } else {
          handlePrev(e);
        }
      } else if (!isMobile && containerRef.current) {
        e.preventDefault();
        containerRef.current.scrollLeft += e.deltaY;
      }
    },
    [isMobile, handleNext, handlePrev],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("wheel", handleWheel, { passive: false });
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        container.removeEventListener("wheel", handleWheel);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [handleKeyDown, handleWheel]);

  return (
    <div className="scrollable-wrapper">
      <div className="scrollable-container" ref={containerRef}>
        <div
          className={`card-container ${currentIndex === -1 ? "active" : ""}`}
          id="start-page"
        >
          <div className={`ui-cards-start`}>
            <h1>UI Cards</h1>
            <p>
              Lernen Sie Betriebssysteme der ausgestellten Computer, und deren
              Eigenschaften, Neuerungen und Einfluss auf andere
              Benutzeroberflächen, näher kennen.
            </p>
            <p>
              Swipen Sie auf Mobile, oder benutzen Sie die Pfeiltasten, um zur
              nächsten Karte zu gelangen.
            </p>
          </div>
        </div>
        {cards.map((card, index) => (
          <div
            key={card.id}
            id={`${card.id}`}
            className={`card-container ${index === currentIndex ? "active" : ""}`}
          >
            <UICard content={card} isMobile={isMobile} />
          </div>
        ))}
        <div
          className={`card-container ${currentIndex === cards.length ? "active" : ""}`}
          id="end-page"
        >
          <div className={`ui-cards-end`}>
            <h1>UI Future</h1>
            <p>
              Wie sieht Ihrer Meinung nach das Betriebssystem der Zukunft aus?
            </p>
            <p>Teilen Sie Ihre Visionen:</p>
            <p>
              <a
                href="https://forms.office.com/e/SXyz6zNzt3"
                target="_blank"
                rel="noreferrer"
              >
                https://forms.office.com/e/SXyz6zNzt3
              </a>
            </p>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="scroll-arrows">
          {currentIndex !== -1 && (
            <button className="scroll-button left" onClick={handlePrev}>
              &lt;
            </button>
          )}
          {currentIndex !== cards.length && (
            <button className="scroll-button right" onClick={handleNext}>
              &gt;
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default UICards;
