import React, { useState } from "react";
import { UICardType } from "../types/UICardType";

interface CardProps {
  content: UICardType;
  isMobile: boolean;
}

const UICard: React.FC<CardProps> = ({ content, isMobile }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`ui-card ${isFlipped ? "flipped" : ""}`}
      onClick={isMobile ? handleFlip : undefined}
    >
      <div className="ui-card-inner">
        <div className="ui-card-front">
          <img
            className="os-type"
            src={`/assets/images/${content.type}_Logo.png`}
            alt={`${content.type} Logo`}
          />
          <h1>{content.os}</h1>
          <img
            className="os-screenshot"
            src={`/assets/images/${content.osImg}.png`}
            alt={`${content.os}`}
          />
          <p>
            <strong>Erscheinungsjahr</strong>: {content.release}
          </p>
          <p>
            <strong>Hardware</strong>: {content.hw}
          </p>
          <p lang="de">{content.description}</p>
          <div className="turn-front">&#10555;</div>
        </div>
        <div className="ui-card-back">
          {isMobile && (
            <img
              className="os-type"
              src={`/assets/images/${content.type}_Logo.png`}
              alt={`${content.type} Logo`}
            />
          )}
          <h2>Innovationen</h2>
          {content.innovations.map((innovation) => (
            <p lang="de">
              <strong>{innovation.title}</strong>: {innovation.description}
            </p>
          ))}
          <p lang="de">{content.relevance}</p>
          <div className="turn-back">&#10555;</div>
        </div>
      </div>
    </div>
  );
};

export default UICard;
