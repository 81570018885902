import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import '../styles/FloatingMenu.css';

const FloatingMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button className="fab" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      {isOpen && (
        <div className="fullscreen-overlay">
          <nav className="fullscreen-nav">
            <Link to="/" className="nav-link" onClick={toggleMenu}>
              Home
            </Link>
            <Link to="/exhibits" className="nav-link" onClick={toggleMenu}>
              Exhibits
            </Link>
            <Link to="/about" className="nav-link" onClick={toggleMenu}>
              About
            </Link>
          </nav>
          <button className="close-button" onClick={toggleMenu}>
            <FaTimes />
          </button>
        </div>
      )}
    </div>
  );
};

export default FloatingMenu;