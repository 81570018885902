import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FloatingMenu from './components/FloatingMenu';
import Placeholder from "./components/Placeholder";
import Exhibits from "./components/Exhibits";
import UICards from "./components/UICards";
import MemoryRecorder from "./components/MemoryRecorder";
import Win98Theme from "./components/Win98Theme";
import About from "./components/About";
import "./styles/index.css";
import "./styles/App.css";

/*function Home() {
  return <h1>Welcome to the Home Page</h1>;
}*/

function App() {
  //const isPlaceholder = process.env.REACT_APP_SHOW_PLACEHOLDER === 'true';
  const [isPlaceholder] = useState(false);

  return (
    <div className="App">
      {isPlaceholder ? (
        <Placeholder />
      ) : (
        <Router>
          <main>
            <Routes>
              <Route path="/" element={<Placeholder />} />
              <Route path="/exhibits" element={<Exhibits />} />
              <Route path="/exhibits/ui-cards" element={<UICards />} />
              <Route path="/exhibits/ui-cards/:id" element={<UICards />} />
              <Route path="/exhibits/memory-recorder" element={<MemoryRecorder />} />
              <Route path="/exhibits/win98-theme" element={<Win98Theme />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </main>
          <FloatingMenu />
        </Router>
      )}
    </div>
  );
}

export default App;
