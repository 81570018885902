import React from "react";
import { Link } from "react-router-dom";
import "../styles/Exhibits.css";

const Exhibits = () => {
  return (
    <div className="exhibits">
      <h1>Exhibition Prototypes</h1>
      <div>
        <p>
          <Link to="/exhibits/ui-cards/start">UI Cards</Link>
        </p>
        <p>
          <Link to="/exhibits/win98-theme">Windows 98 Theme</Link>
        </p>
        <p>
          <Link to="/exhibits/memory-recorder">Memory Recorder</Link>
        </p>
      </div>
    </div>
  );
};

export default Exhibits;
