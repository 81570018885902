import { FormControl, FormHelperText } from "@mui/joy";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import Checkbox from "@mui/joy/Checkbox";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Button from "@mui/joy/Button";
import "../styles/MemoryRecorder.css";
import "react-toastify/dist/ReactToastify.css";

const MemoryRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [consentGiven, setConsentGiven] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [textContent, setTextContent] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [isTextSubmitEnabled, setIsTextSubmitEnabled] = useState(false);
  const [isAudioSubmitEnabled, setIsAudioSubmitEnabled] = useState(false);
  //const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<any[]>([]);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    const isValidYear =
      yearOfBirth !== "" &&
      parseInt(yearOfBirth) > 1930 &&
      parseInt(yearOfBirth) < new Date().getFullYear();
    const hasText = textContent.trim() !== "";
    setIsTextSubmitEnabled(hasText && isValidYear);
  }, [yearOfBirth, textContent]);

  useEffect(() => {
    const isValidYear =
      yearOfBirth !== "" &&
      parseInt(yearOfBirth) > 1930 &&
      parseInt(yearOfBirth) < new Date().getFullYear();
    const hasAudio = audioUrl !== null && audioBlob !== null;
    setIsAudioSubmitEnabled(hasAudio && isValidYear);
  }, [yearOfBirth, audioUrl, audioBlob]);

  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentGiven(event.target.checked);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    //setIsEmailValid(validateEmail(email));
  };

  /*const validateEmail = (email: string): boolean => {
    // Basic email validation pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };*/

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextContent(event.target.value);
  };

  const handleYearChange = (
    event: React.SyntheticEvent | null,
    newValue: string | null,
  ) => {
    setYearOfBirth(newValue as string);
  };

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = (event: any) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    };
    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: "audio/webm",
      });

      // Check the size of the audioBlob (https://vercel.com/docs/storage/vercel-blob)
      const sizeInMB = audioBlob.size / (1024 * 1024);
      if (sizeInMB > 4.5) {
        toast.error(
          "Audiodatei grösser als 5.6 MB. Bitte kürzere Aufnahme erstellen.",
        );
        return;
      }

      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      setAudioBlob(audioBlob);
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      audioChunksRef.current = [];
    }
  };

  const submitAudio = async () => {
    if (!audioBlob) return;

    const formData = new FormData();
    formData.append("audio", audioBlob, "recording.webm");
    formData.append("yearOfBirth", yearOfBirth);
    formData.append("email", email);

    try {
      const response = await api.post("/api/memory/submit-audio", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
      } else if (response.data.statusCode === 500) {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(`Error uploading audio: ${error}`);
    }
  };

  const submitText = async () => {
    try {
      const response = await api.post("/api/memory/submit-text", {
        textContent,
        yearOfBirth,
        email,
      });

      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
      } else if (response.data.statusCode === 500) {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(`Error submitting text audio: ${error}`);
    }
  };

  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1930; year--) {
      years.push(
        <Option value={year} key={year}>
          {year}
        </Option>,
      );
    }
    return years;
  };

  return (
    <div className="memory-recorder">
      <ToastContainer position="bottom-center" autoClose={4000} />
      <h1>Memory Recorder</h1>
      <p>
        Ich interessiere mich für Ihre ersten Erfahrungen mit elektronischen Geräten, die Sie benutzt haben, wie PCs, Laptops oder Smartphones. Versetzen Sie sich zurück und teilen Sie Ihre Ihre Erinnerungen.
        <ul>
          <li>Was war das erste Gerät, das Sie benutzt haben? (PC, Laptop, Smartphone)</li>
          <li>Was ist Ihnen von der Benutzung in Erinnerung geblieben?
            <ul>
              <li><strong>Haptik</strong>: Wie hat sich das Gerät in Ihren Händen angefühlt? War die Tastatur-, Maus- oder Touch-Interaktion besonders?</li>
              <li><strong>Visuelles</strong>: Wie sah der Bildschirm aus? Gab es Farben, Grafiken, Animationen oder Designs, die Ihnen im Gedächtnis geblieben sind?</li>
              <li><strong>Geräusche</strong>: Gab es bestimmte Geräusche, z. B. das Startgeräusch, Tippgeräusche oder Benachrichtigungen, an die Sie sich besonders erinnern?</li>
              <li><strong>Software</strong>: Können Sie sich an das Betriebssystem oder andere Software und Games erinnern? War die Bedienung einfach oder gab es Probleme und worauf musste geachtet werden? Wie ging die Installation vonstatten?</li>
              <li><strong>Legacy</strong>: Wie hat die Benutzung des Geräts Ihre Wahrnehmung der Technologie oder der Gesellschaft zu dieser Zeit beeinflusst? Besitzen Sie das Gerät noch oder erinnern Sie sich gerne daran? Hat es einen sentimentalen Wert?</li>
            </ul>
          </li>
        </ul>
        Sie können Ihre Antwort entweder in das Textfeld schreiben oder eine Audionachricht aufnehmen und hochladen.
        Vielen Dank!
      </p>
      <div className="consent-check">
        <Checkbox
          label="Ich bin damit einverstanden, dass mein Beitrag für eine Masterarbeit ausgewertet wird."
          checked={consentGiven}
          onChange={handleConsentChange}
        />
      </div>
      {consentGiven && (
        <>
          <div className="email-input">
            <FormControl>
              <Input
                placeholder="E-Mail"
                variant="outlined"
                type="email"
                value={email}
                onChange={handleEmailChange}
              />
              <FormHelperText>
                Sie können die E-Mail-Adresse angeben, wenn Sie damit
                einverstanden sind, dass Ihr Beitrag im Museumskontext genutzt
                werden kann. Sie würden davor kontaktiert werden.
              </FormHelperText>
            </FormControl>
          </div>
          <div className="year-input">
            <div className="year-selector">
              <FormControl>
                <Select defaultValue={yearOfBirth} onChange={handleYearChange}>
                  <Option value="">Jahrgang / year of birth</Option>
                  {renderYearOptions()}
                </Select>
                <FormHelperText>
                  Der Jahrgang wird benötigt, um Ihren Beitrag historisch
                  einzuordnen.
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <AccordionGroup size="lg" className="accordions">
            <Accordion>
              <AccordionSummary>Text schreiben / write text</AccordionSummary>
              <AccordionDetails>
                <div className="text-input">
                  <Textarea
                    minRows={5}
                    value={textContent}
                    onChange={handleTextChange}
                    placeholder="Ich erinnere mich gut an... / I remember well..."
                  />
                  <Button
                    className="submit-button"
                    onClick={submitText}
                    disabled={!isTextSubmitEnabled}
                  >
                    Upload Text
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>
                Audio aufnehmen / record audio
              </AccordionSummary>
              <AccordionDetails>
                <div className="audio-input">
                  <div className="recording-controls">
                    {isRecording ? (
                      <Button
                        className="stop-button"
                        onClick={stopRecording}
                        variant="soft"
                        color="danger"
                      >
                        Stop Recording
                      </Button>
                    ) : (
                      <Button
                        className="start-button"
                        onClick={startRecording}
                        variant="soft"
                        color="success"
                      >
                        Start Recording
                      </Button>
                    )}
                  </div>
                  {audioUrl && (
                    <div className="audio-preview">
                      <p>Audio Preview</p>
                      <audio controls src={audioUrl}></audio>
                    </div>
                  )}
                  <Button
                    className="submit-button"
                    onClick={submitAudio}
                    disabled={!isAudioSubmitEnabled}
                  >
                    Upload Audio
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </>
      )}
    </div>
  );
};

export default MemoryRecorder;
